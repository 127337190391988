.questionanswercontainer {
    max-width: 800px;
    margin: 20px auto;
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .post {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 15px;
  }

  .post:last-child {
    border-bottom: none;
  }

  .profile-pic {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
  }

  .content {
    flex-grow: 1;
  }

  .user-info {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
  }

  .user-info .name {
    font-weight: bold;
    color: #333;
  }

  .user-info .timestamp {
    color: #999;
  }

  .text {
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    margin-bottom: 10px;
  }

  .actions {
    display: flex;
    gap: 10px;
  }

  .button {
    padding: 5px 10px;
    font-size: 14px;
    color: #fff;
    background-color: goldenrod;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .button.delete {
    background-color: #dc3545;
  }

  .button:hover {
    opacity: 0.9;
  }

  .answer {
    margin-left: 65px;
    border-left: 2px solid #e0e0e0;
    padding-left: 15px;
  }