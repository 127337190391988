header{
    /* margin-top: -35px; */
    padding: 0;
   width: 100%;
    height: 80px;
    background: rgb(255, 120, 30);
  }

  footer {
   height: 80px;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(255, 120, 30);
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
  }
  header nav{
    width: 90%;
    margin:auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  header nav .left{
    color: white;
  }
  
  header nav .right ul{
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
  }
  
  header nav .right ul li{
    font-size: 19px;
    padding: 10px;
    text-transform: capitalize;
    color: white;
    cursor: pointer;
  
  }
  
  
  header nav .right ul li a{
    text-decoration: none;
    color: white;
  
  }

  body {
    min-height: 100vh;
  }