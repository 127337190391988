.app {
    display: flex;
    padding: 2rem;
    justify-content: space-evenly;
    font-family: Verdana, Geneva, Tahoma, sans-serif
  }
  
  .map-container {
    width: 50%;
    border: 2px solid black;
    height: 300px;
  }