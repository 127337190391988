select {

    /* styling */
    background-color: white;
    border: thin solid blue;
    border-radius: 4px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;
  
    /* reset */
  
         
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  
  
  /* arrows */
  
  select.classic {
    background-image:
      linear-gradient(45deg, transparent 50%, blue 50%),
      linear-gradient(135deg, blue 50%, transparent 50%),
      linear-gradient(to right, skyblue, skyblue);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      100% 0;
    background-size:
      5px 5px,
      5px 5px,
      2.5em 2.5em;
    background-repeat: no-repeat;
  }
  
  select.classic:focus {
    background-image:
      linear-gradient(45deg, white 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, white 50%),
      linear-gradient(to right, gray, gray);
    background-position:
      calc(100% - 15px) 1em,
      calc(100% - 20px) 1em,
      100% 0;
    background-size:
      5px 5px,
      5px 5px,
      2.5em 2.5em;
    background-repeat: no-repeat;
    border-color: grey;
    outline: 0;
  }

  .label {
    margin: 15px; 
    font-weight: bold;
    color:darkgoldenrod;
  }

  .textbox {

    background-color: white;
    border: thin solid blue;
    border-radius: 4px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0.5em 0.5em 0.5em 0.5em;
  
    /* reset */
  
         
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

  }

  .elementdiv{
margin:15px;
  }

  .verticalscrolldiv{
    margin: 5px;
    padding: 5px;
    background-color:lavender;
            width: 50%;
            height: 300px;
            overflow: auto;
            text-align: center;
            margin: auto;
           
  }

  .form-button {
    padding: 10px 20px;
    margin: 15px 0 0 0;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    cursor:pointer;
    background-color: rgb(255,120,30);
    color: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }

  .results-answer {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    text-align: left;
    min-width: 60%;
    max-width: 60%;
  }

  .results-answer2 {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    text-align: left;
    min-width: 90%;
    max-width: 90%;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }