
/*** Spinner Start ***/
/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}
/*** Spinner End ***/

.back-to-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    display: flex;
    width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    z-index: 99;
}

/*** Button Start ***/
.btn {
    font-weight: 600;
    transition: .5s;
}

.btn-square {
    width: 32px;
    height: 32px;
}

.btn-sm-square {
    width: 34px;
    height: 34px;
}

.btn-md-square {
    width: 44px;
    height: 44px;
}

.btn-lg-square {
    width: 56px;
    height: 56px;
}

.btn-xl-square {
    width: 66px;
    height: 66px;
}

.btn-square,
.btn-sm-square,
.btn-md-square,
.btn-lg-square,
.btn-xl-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50%;
}

.btn.btn-primary {
    background: var(--bs-primary) !important;
    color: var(--bs-white) !important;
    font-family: 'Roboto', sans-serif;
    border: none;
    font-weight: 400;
    transition: 0.5s;
}

.btn.btn-primary:hover {
    background: var(--bs-dark) !important;
    color: var(--bs-light) !important;
}

.btn.btn-dark {
    background: var(--bs-dark) !important;
    color: var(--bs-white) !important;
    font-family: 'Roboto', sans-serif;
    border: none;
    font-weight: 400;
    transition: 0.5s;
}

.btn.btn-dark:hover {
    background: var(--bs-white) !important;
    color: var(--bs-dark) !important;
}

.btn.btn-light {
    background: var(--bs-primary) !important;
    color: var(--bs-white) !important;
    font-family: 'Roboto', sans-serif;
    border: none;
    font-weight: 400;
    transition: 0.5s;
}

.btn.btn-light:hover {
    background: var(--bs-white) !important;
    color: var(--bs-dark) !important;
}
/*** Topbar Start ***/
.topbar {
    padding: 2px 10px 2px 20px;
    background: var(--bs-dark) !important;
}

@media (max-width: 768px) {
    .topbar {
        display: none;    
    }
}
/*** Topbar End ***/

/*** Navbar ***/
.sticky-top,
.sticky-top .container {
    transition: 0.5s !important;
}

.navbar .navbar-nav .nav-item.nav-link,
.navbar .navbar-nav .nav-link {
    color: var(--bs-dark) !important;
}

.navbar .navbar-nav .nav-item {
    position: relative;
    margin: 0 5px !important;
}

.navbar .navbar-nav .nav-item::before,
.navbar .navbar-nav .nav-item::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border: 0px solid var(--bs-primary);
    transition: 0.5s;
}

.navbar .navbar-nav .nav-item::before {
    top: 0;
    left: 0;
}
.navbar .navbar-nav .nav-item::after {
    bottom: 0;
    right: 0;
}

.navbar .navbar-nav .nav-item:hover::after,
.navbar .navbar-nav .nav-item:hover::before,
.navbar .navbar-nav .nav-item.active::after,
.navbar .navbar-nav .nav-item.active::before {
    width: 100%;
    border-width: 1px;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active,
.sticky-top .navbar .navbar-nav .nav-link:hover,
.sticky-top .navbar .navbar-nav .nav-link.active {
    color: var(--bs-primary) !important;
}

.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107" !important;
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    vertical-align: middle;
}

@media (min-width: 1200px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        visibility: hidden;
        top: 100%;
        transform: rotateX(-75deg);
        transform-origin: 0% 0%;
        border: 0;
        border-radius: 10px;
        margin-top: 20px !important;
        transition: .5s;
        opacity: 0;
        z-index: 9;
    }
}

@media (max-width: 1200px) {
    .navbar .nav-item .dropdown-menu {
        margin-top: 15px !important;
        transition: 0.5s;
    }
}

@media (max-width: 991px) {
    .navbar .navbar-nav .nav-item.nav-link {
        margin: 15px 0 !important;
    }

}

.dropdown .dropdown-menu a:hover {
    background: var(--bs-primary) !important;
    color: var(--bs-white);
}

.navbar .nav-item:hover .dropdown-menu {
    transform: rotateX(0deg);
    visibility: visible;
    background: var(--bs-light) !important;
    margin-top: 20px !important;
    transition: .5s;
    opacity: 1;
}

.navbar .navbar-toggler {
    padding: 8px 15px;
    color: var(--bs-dark);
    background: var(--bs-primary);
}

#searchModal .modal-content {
    background: rgba(250, 250, 250, .6);
}
/*** Navbar End ***/

/*** Carousel Header Start ***/
.header-carousel .header-carousel-item {
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.owl-prev,
.owl-next {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 50%;
    transform: translateY(-50%);
    padding: 20px 20px;
    border-radius: 40px;
    background: var(--bs-primary);
    color: var(--bs-white);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
}

.owl-prev {
    left: 30px;
}

.owl-next {
    right: 30px;
}

.owl-prev:hover,
.owl-next:hover {
    background: var(--bs-dark) !important;
    color: var(--bs-white) !important;
}

.header-carousel .header-carousel-item-img-1,
.header-carousel .header-carousel-item-img-2,
.header-carousel .header-carousel-item-img-3 {
    position: relative;
    overflow: hidden;
}

.header-carousel .header-carousel-item-img-1::before,
.header-carousel .header-carousel-item-img-2::before,
.header-carousel .header-carousel-item-img-3::before {
    content: "";
    position: absolute;
    width: 600px;
    height: 600px;
    top: -300px;
    right: -300px;
    border-radius: 300px;
    border: 100px solid rgba(68, 210, 246, 0.2);
    animation: RotateMoveCircle 10s linear infinite;
    background: transparent;
    z-index: 2 !important;
}

@keyframes RotateMoveCircle {
    0% {top: -400px;}
    50%   {right: -200px;}
    75%   {top: -200px;}
    100%  {top: -400px;}
}

.header-carousel .header-carousel-item-img-1::after,
.header-carousel .header-carousel-item-img-2::after,
.header-carousel .header-carousel-item-img-3::after {
    content: "";
    position: absolute;
    width: 150px;
    height: 1200px;
    top: -150px;
    left: 70px;
    transform: rotate(-30deg);
    background: rgba(68, 210, 246, 0.2);
    animation: RotateMoveRight 5s linear infinite;
    z-index: 2 !important;
}

@keyframes RotateMoveRight {
    0%   {left: 0px;}
    50%   {left: 70px;}
    100%  {left: 0px;}
}

.header-carousel .header-carousel-item::before {
    content: "";
    position: absolute;
    width: 300px;
    height: 1200px;
    top: -150px;
    left: -265px;
    transform: rotate(-30deg);
    background: var(--bs-dark);
    animation: RotateMoveLeft 5s linear infinite;
    opacity: 0.7;
    z-index: 2 !important;
}

@keyframes RotateMoveLeft {
    0%   {left: -240px;}
    50%   {left: -300px;}
    100%  {left: -240px;}
}

.header-carousel .header-carousel-item::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2 !important;
}

.header-carousel .header-carousel-item .carousel-caption {
    position: absolute;
    width: 80%;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 9;
}

@media (max-width: 992px) {
    .header-carousel .header-carousel-item .carousel-caption {
        width: 100% !important;
        margin-left: 0 !important;
        padding: 0 10px;
    }
    .header-carousel-item img {
        height: 700px;
        object-fit: cover;
    }

    .owl-prev,
    .owl-next {
        top: 40px !important;
    }

    .owl-prev {
        left: 65%;
    }
}
/*** Header Carousel End ***/

/*** Single Page Hero Header Start ***/
.bg-breadcrumb {
    position: relative;
    overflow: hidden;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/carousel-1.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 60px 0 60px 0;
}

.bg-breadcrumb .breadcrumb-item a {
    color: var(--bs-white) !important;
}

.bg-breadcrumb .bg-breadcrumb-single {
    position: absolute;
    width: 500px;
    height: 1200px;
    top: 0px;
    left: 0;
    margin-left: 30px;
    transform: rotate(-30deg);
    background: var(--bs-dark);
    animation: RotateMoveLeft 5s linear infinite;
    opacity: 0.7;
    z-index: 2 !important;
}


.bg-breadcrumb::before {
    content: "";
    position: absolute;
    width: 400px;
    height: 400px;
    top: -150px;
    right: -150px;
    border-radius: 200px;
    border: 80px solid rgba(68, 210, 246, 0.2);
    animation: RotateMoveCircle 10s linear infinite;
    background: transparent;
    z-index: 2 !important;
}

@keyframes RotateMoveCircle {
    0% {top: -200px;}
    50%   {right: -100px;}
    75%   {top: -100px;}
    100%  {top: -200px;}
}


.bg-breadcrumb::after {
    content: "";
    position: absolute;
    width: 150px;
    height: 1200px;
    top: -150px;
    left: 0;
    margin-left: 160px;
    transform: rotate(-30deg);
    background: rgba(68, 210, 246, 0.2);
    animation: RotateMoveRight 5s linear infinite;
    z-index: 2 !important;
}
/*** Single Page Hero Header End ***/


/*** About Start ***/
.about .text {
    position: relative;
}

.about .text::after {
    content: "";
    width: 4px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--bs-primary);
}
/*** About End ***/

/*** Service Start ***/
.service .service-item {
    box-shadow: 0 0 45px rgba(0, 0, 0, 0.3);
}

.service .service-item .service-img {
    position: relative;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.service .service-item .service-img img {
    transition: 0.5s;
}

.service .service-item:hover .service-img img {
    transform: scale(1.2);
}

.service .service-item .service-img::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    right: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: rgba(68, 210, 246, 0.4);
    transition: 0.5s;
}

.service .service-item:hover .service-img::after {
    width: 100% !important;
    height: 100% !important;
}

.service .service-item .service-content {
    position: relative;
}

.service .service-item .service-content::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: var(--bs-dark);
    transition: 0.5s;
    z-index: 1;
}

.service .service-item:hover .service-content::after {
    width: 100% !important;
    height: 100% !important;
}

.service .service-item .service-content .service-content-inner {
    transition: 0.5s;
}

.service .service-item:hover .service-content .service-content-inner {
    position: relative;
    color: var(--bs-white) !important;
    transition: 0.5s;
    z-index: 2;
}

.service .service-item:hover .service-content .service-content-inner a.h4 {
    color: var(--bs-white) !important;
    transition: 0.5s;
}

.service .service-item:hover .service-content .service-content-inner a.h4:hover {
    color: var(--bs-primary) !important;
}
/*** Service End ***/

/*** Project Start ***/
.project .project-carousel.owl-carousel {
    height: 100%;
}

.project .project-carousel .project-item {
    position: relative;
    overflow: hidden;
}

.project .project-carousel .project-item .project-img {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.project .project-carousel .project-item .project-img::after {
    content: "";
    width: 100%;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    background: rgba(68, 210, 246, 0.4);
    transition: 0.5s;
}

.project .project-carousel .project-item:hover .project-img::after {
    height: 100%;
}

.project .project-carousel .project-item .project-img img {
    transition: 0.5s;
}

.project .project-carousel .project-item:hover .project-img img {
    transform: scale(1.2);
}

.project .project-carousel .project-item .project-content {
    position: relative;
    width: 75%;
    height: 100%;
    bottom: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
}

.project .project-carousel .project-item .project-content::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    border-radius: 10px !important;
    background: var(--bs-dark);
    transition: 0.5s;
}

.project .project-carousel .project-item .project-content .project-content-inner {
    position: relative;
    z-index: 2;
}

.project .project-carousel .project-item:hover .project-content .project-content-inner p,
.project .project-carousel .project-item:hover .project-content .project-content-inner a.h4,
.project .project-carousel .project-item:hover .project-content .project-content-inner .project-icon i {
    color: var(--bs-white) !important;
    transition: 0.5s;
}

.project .project-carousel .project-item:hover .project-content .project-content-inner a.h4:hover {
    color: var(--bs-primary) !important;
}

.project .project-carousel .project-item:hover .project-content::after {
    height: 100%;
}

.project-carousel .owl-dots {
    position: absolute;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -120px;
    transition: 0.5s;
}

.project-carousel .owl-dots .owl-dot {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-right: 15px;
    background: var(--bs-dark);
    transition: 0.5s;
}

.project-carousel .owl-dots .owl-dot.active {
    width: 30px;
    height: 30px;
    border-radius: 40px;
    border: 8px solid var(--bs-primary);
}
/*** Project End ***/

/*** Blog Start ***/
.blog .blog-item .project-img {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    transition: 0.5s;
}

.blog .blog-item .project-img .blog-plus-icon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: rgba(68, 210, 246, 0.2);
    transition: 0.5s;
    opacity: 0;
}

.blog .blog-item:hover .project-img .blog-plus-icon {
    opacity: 1;
}

.blog .blog-item .project-img img {
    transition: 0.5s;
}

.blog .blog-item:hover .project-img img {
    transform: scale(1.3);
}

/*** Blog End ***/

/*** Team Start ***/
.team .team-item {
    position: relative;
    overflow: hidden;
    border: 1px solid var(--bs-dark);
    transition: 0.5s;
}

.team .team-item:hover {
    border: none !important;
}

.team .team-item::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--bs-primary) transparent transparent var(--bs-primary) !important;
    z-index: 5;
    transition: 1s;
    opacity: 0;
}

.team .team-item::before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 10px;
    border: 1px solid;
    border-color: transparent var(--bs-primary) var(--bs-primary) transparent !important;
    z-index: 5;
    transition: 1s;
    opacity: 0;
}

.team .team-item:hover:after,
.team .team-item:hover::before {
    width: 100% !important;
    height: 100% !important;
    opacity: 1;
}

.team .team-item .team-img {
    position: relative;
    overflow: hidden;
}

.team .team-item .team-img::after {
    content: "";
    width: 100%;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: rgba(68, 210, 246, 0.2);
    transition: 0.5s;
}

.team .team-item:hover .team-img::after {
    height: 100%;
}

.team .team-item .team-img .team-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 8;
    transition: 0.5s;
}

.team .team-item .team-img .team-icon .team-icon-share {
    position: relative;
    margin-top: -200px;
    transition: 0.5s;
    z-index: 9;
}

.team .team-item:hover .team-img .team-icon .team-icon-share {
    margin-top: 0 !important;
}

.team .team-item .team-content {
    position: relative;
}

.team .team-item .team-content::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--bs-primary) !important;
    transition: 0.5s;
}

.team .team-item .team-content::before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: var(--bs-primary) !important;
    transition: 0.5s;
}

.team .team-item:hover .team-content::after,
.team .team-item:hover .team-content::before {
    width: 100% !important;
    height: 50% !important;
}

.team .team-item:hover .team-content .team-content-inner {
    position: relative;
    color: var(--bs-white);
    z-index: 2;
}

.team .team-item .team-content .team-content-inner h4,
.team .team-item .team-content .team-content-inner p {
    transition: 0.5s;
}

.team .team-item:hover .team-content .team-content-inner p {
    color: var(--bs-white) !important;
}

.team .team-item:hover .team-content .team-content-inner h4 {
    color: var(--bs-dark) !important;
}
/*** Team End ***/

/*** Testimonial Start ***/
.testimonial .testimonial-carousel {
    position: relative;
}

.testimonial .testimonial-carousel .owl-dots {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.testimonial .testimonial-carousel .owl-dot {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    width: 15px;
    height: 15px;
    background: var(--bs-light);
    border: 1px solid var(--bs-primary);
    border-radius: 10px;
    transition: 0.5s;
}

.testimonial .testimonial-carousel .owl-dot.active {
    width: 40px;
    background: var(--bs-primary);
}
/*** Testimonial End ***/

/*** FAQ'S Start ***/
.faq {
    position: relative;
    overflow: hidden;
}

.faq::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-image: url(../img/bg.png);
    object-fit: cover;
    z-index: -1;
}

.faq .faq-img {
    position: relative;
}

.faq .faq-img .faq-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

/*** FAQ'S End ***/

/*** Footer Start ***/
.footer {
    background: var(--bs-dark);
}
.footer .footer-item a {
    line-height: 35px;
    color: var(--bs-body);
    transition: 0.5s;
}

.footer .footer-item p {
    line-height: 35px;
}

.footer .footer-item a:hover {
    letter-spacing: 1px;
    color: var(--bs-primary);
}

.footer .footer-item-post a {
    transition: 0.5s;
}

.footer .footer-item-post a:hover {
    color: var(--bs-primary) !important;
}

.footer .footer-item-post .footer-btn a {
    transition: 0.5s;
}

.footer .footer-item-post .footer-btn a:hover {
    color: var(--bs-dark);
}
/*** Footer End ***/

/*** copyright Start ***/
.copyright {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    background: var(--bs-dark) !important;
}
/*** copyright end ***/